<template>
    <div class=" h-100  pt-2">
        <div class="breadcrumbactive">
            <div >
                <span v-if="loggedInUser.user_has == 'organization'" >Manage Organization </span>
                <span v-else-if="loggedInUser.user_has == 'companies'" >Manage Companies </span>
                <span>
                    <router-link to="/organization/listview" class="text-white pl-2 text-line">List View</router-link>
                </span>
                <div>Product</div>
            </div>
        </div>
        <div  class="start-overflow">
            <div class="create-company-container w-100 ">
                <div style="width:100%;" class="mb-5">
                    <div class="page-layout carded left-sidebar"
                        style="border: 0px solid rgb(148, 190, 240) !important;border-radius: 0px !important;">
                        <div class="page-content-wrapper" style="overflow-x:auto">
                            <div class="page-content">
                                <div class="demo-content create-company-container w-100">
                                    <div v-if="$route.path.startsWith('/employee/createemployee')"
                                        style="background-color: #00448b;color:#fff;text-align:center;padding:12px;text-transform:uppercase;border-radius: 10px 10px 0px 0px;">
                                        Set Product Permissions</div>
                                    <div v-else
                                        style="background-color: #00448b;color:#fff;text-align:center;padding:12px;text-transform:uppercase;border-radius: 10px 10px 0px 0px;">
                                        Product List</div>
                                    <table class="table table-new-template table-brand" style="border:1px solid grey">
                                        <thead class="text-left">
                                            <tr>
                                                <th>PRODUCTS
                                                    <el-tooltip class="item " effect="dark"
                                                        content="Select Product to allow module-wise permission"
                                                        placement="top">
                                                        <i
                                                            class="icons icon-information defaultDarkBlue fs-14 mb-3"></i>
                                                    </el-tooltip>
                                                </th>
                                                <th class="text-left">LIST OF MODULE PERMISSION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="ajax_call_in_progress">
                                                <td colspan="5" class="text-center">
                                                    <div class="d-flex align-items-center justify-content-center w-100">
                                                        <half-circle-spinner :animation-duration="1000" :size="60"
                                                            color="#00448b" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-else-if="!ajax_call_in_progress && moduleList.length == 0"
                                                class="text-center font-weight-bold">
                                                <td colspan="5" class="text-center">No Products Found</td>
                                            </tr>
                                            <template v-else v-for="(product,index) in moduleList">
                                                <tr  :key="index+'12'"
                                                    class="sent-call-tr">
                                                    <td> 
                                                        <span class=" pt-3 pr-5 pl-2">
                                                             <i v-if="product.is_expanded" @click="expandData(product)"
                                                                class="icon-chevron-down text-black"></i>
                                                            <i v-else @click="expandData(product)"
                                                                class="icon-chevron-right text-black"></i>
                                                        </span>
                                                        <img width="26px" height="20px" class="pr-2" :src="product.logo">{{product.product_name}}
                                                    </td>
                                                    <td v-if="!expandShow">{{"-"}}</td>
                                                    <td v-else  :key="index" v-for="(submodule,index) in product.subModuleList">{{submodule.is_enabled ? submodule.module_name : "-"}}</td>
                                                    <td class="text-center fw-600" >MODULES</td>
                                                    <td class="text-center fw-600" >PERMISSION</td>
                                                </tr>
                                                <template  v-if="product.is_expanded "> 
                                                    <tr  v-for="(submodule,index) in product.subModuleList" :key="index" >
                                                        <td></td>
                                                        <td></td>
                                                        <td class="text-center" >{{submodule.module_name}}</td>
                                                        <td class="text-center" >
                                                            <el-switch v-model="submodule.is_enabled" @change="changePermission(submodule, index)"
                                                                active-color="#2cc36a" inactive-color="#bcb8b8">
                                                            </el-switch>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" @hideSuccess="hideSuccess" :success_message="success_message"
            modal_name="create-success"></create-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>

</template>
<script>
    import companies from '../mixins/companies';
    const CreateSuccess = () => import('./CreateSuccess');
    import {
        SweetModal
    } from 'sweet-modal-vue';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import product from '../mixins/organization';
    import {
        EventBus
    } from '../eventBus/nav-bar-event';
    export default {
        mixins: [companies, product],
        props: ["emp_data"],
        data() {
            return {
                create_success: false,
                permissionProduct: true,
                warning_msg: '',
                success_msg: '',
                skip: 0,
                limit: 10,
                search_key: '',
                ajax_call_in_progress: false,
                count: 0,
                scrollable: false,
                product_id: '',
                moduleList: [],
                showDataList: false,
                subModuleList: [],
                organization_id: '',
                subsidiary_id:'',
                selected_data:'',
                expandShow: false,
            }
        },
        components: {
            HalfCircleSpinner,
            CreateSuccess,
            SweetModal,
            EventBus
        },
        methods: {
            expandData(product,index) {
                product.is_expanded = !product.is_expanded
                this.$forceUpdate()
                if(product.is_expanded == true ) {
                    this.getSubsidiarySubModule(product)
                    this.$forceUpdate()
                } 
            },
            hideSuccess() {
                this.create_success = false;
                this.$router.push("/employee/listemployee/cmpid/" + this.$route.params.company_id);
            },
            cancel() {
                this.$router.push('/employee/listemployee/cmpid/' + this.$route.params.company_id);
                // this.$emit('switchWizard1');
            },
            backToScreen() {
                this.$emit('switchWizard1');
            },
            async getSubsidiaryModule() {
                this.ajax_call_in_progress = true
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                    };
                    let response = await this.subsidiaryProductList(params, 'list');
                    this.moduleList = response.response
                     this.moduleList.forEach((element,index) => {
                        element['is_expanded'] = false
                        element['subModuleList'] = []                        
                    });

                    if (response.status_id == 1) {
                        // this.success_msg = response.message
                        // this.$refs.success_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.success_modal.close();
                        // }, 2000);
                    }
                } catch (err) {
                    this.ajax_call_in_progress = false
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                    
                }
                this.ajax_call_in_progress = false
            },
            async getSubsidiarySubModule(product) {
                this.ajax_call_in_progress = true
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        product_id: product.product_id
                    };
                    let response = await this.subsidiaryProductSubModules(params, 'list'); 
                    product.subModuleList = response.response
                    product.subModuleList.forEach((element,index) => {
                        this.show_subsidiary = element.is_enabled
                        this.subsidiary_id = element.id
                        if(this.show_subsidiary == true) {
                            this.expandShow = true
                        }else{
                            this.expandShow = false
                        }
                        //this.selected_data = element.module_name
                    });
                    

                    if (response.status_id == 1) {
                        // this.success_msg = response.message
                        // this.$refs.success_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.success_modal.close();
                        // }, 2000);
                    }
                    this.ajax_call_in_progress = false
                } catch (err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            changePermission(val) {
                this.selected_data = val.module_name
                 this.$forceUpdate()
                this.subsidiaryToggleSwitch(val);
            },
            async subsidiaryToggleSwitch(value) {
                this.ajax_call_in_progress = true
                try {
                    let params = {
                        organization_id:this.organization_id,
                        id : value.id,
                        is_enabled : value.is_enabled
                    };
                    console.log(params)
                   
                    let response = await this.allowSubsidiaryToggle(params, 'single');
                    this.selected_data = value.name
                    if (response.status_id == 1) {

                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                        }, 2000);
                    }
                } catch (err) {
                    this.warning_msg = err;
                    this.ajax_call_in_progress = false
                    this.allow_subsidiary = false
                    this.$refs.warning_modal.open();
                }
                this.ajax_call_in_progress = false
            }
        },
        created() {
            setTimeout(() => {
                 this.organization_id = localStorage.getItem('orgId') 
                 console.log(this.organization_id)
                 this.$forceUpdate()
                
            }, 300); 
        },
        mounted() {
            this.getSubsidiaryModule() 

        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        }
    }
</script>
<style>
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
        /*border: 1px solid grey;*/
    }

    .overflow-auto {
        overflow: auto;
    }

    .table-new-template>tbody>tr:nth-of-type(even) td {
        background-color: #dfe8f4;
        padding: 10px;
    }

    .table-new-template>tbody>tr:nth-of-type(odd) td {
        background-color: #fff;
        padding: 10px;

    }

    .table-new-template>tbody>tr:nth-of-type(even) td div>.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(even) td div>.diginew-multiselect>.multiselect__tags {
        background: #dfe8f4 !important;
    }

    .table-new-template>tbody>tr:nth-of-type(odd) td div>.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(odd) td div>.diginew-multiselect>.multiselect__tags {
        background: #fff !important;
    }

    .table-new-template thead tr th {
        vertical-align: middle;
        color: #005dae;
        text-transform: capitalize;
        font-weight: 600;
        letter-spacing: 1px;
        white-space: nowrap;
        font-size: 15px;
        text-align: left
    }

    .table-new-template thead th,
    .table-new-template tbody td {
        align-items: center;
        padding: 10px !important;
        ;

    }


    .table-new-template tbody tr,
    .table-new-template tbody td {
        align-items: center;
        font-weight: 600;
        text-align: left
    }

    .placeholder {
        color: #000 !important;
    }

    .table-new-template>tbody>tr>td div>.diginew-multiselect>.multiselect__tags>.multiselect__placeholder {
        color: #000;
    }

    .btn-new-cancel {
        border-radius: 5px !important;
        background-color: #d51650;
        padding-left: 25px !important;
        padding-right: 25px !important;
        color: #fff !important
    }

    .table-new-template>tbody>tr:nth-of-type(odd) td div>.diginew-multiselect>.multiselect__tags>.multiselect__input,
    .multiselect__single {
        background-color: #fff
    }

    .table-new-template>tbody>tr:nth-of-type(even) td div>.diginew-multiselect>.multiselect__tags>.multiselect__input,
    .multiselect__single {
        background: #dfe8f4 !important;
    }

    .multiselect__input {
        margin-bottom: 0px !important;
    }

    .multiselect__placeholder {
        padding: 0px !important;
    }
    .text-line {
        text-decoration :none !important;
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td {
        background-color: #ecf1f9 !important;
    }
    .table-new-template>tbody>tr:nth-of-type(even) td {
        background-color: #fff !important;
    }
</style>